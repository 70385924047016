import { useRef, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import { ArrowLeft } from "@phosphor-icons/react";

function VideoPlayer({ url, thumbnail, onClose }) {
  const videoRef = useRef();

  const doFullscreen = async function () {
    try {
      const screenfull = await import("screenfull");
      if (screenfull.default && screenfull.default.isEnabled) {
        await screenfull.default.request();
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const closeScreen = async () => {
    const screenfull = await import("screenfull");
    if (screenfull.default.isEnabled) {
      screenfull.default.on("change", () => {
        if (!screenfull.default.isFullscreen) {
          onClose();
        }
      });
    }
  };

  useEffect(() => {
    closeScreen();
  }, []);

  useEffect(() => {
    doFullscreen();
    document.body.classList.add("overflow-hidden");

    return async () => {
      try {
        const screenfull = await import("screenfull");
        document.body.classList.remove("overflow-hidden");
        if (screenfull.default.isEnabled && screenfull.default.isFullscreen) {
          await screenfull.default.exit();
        }
      } catch (error) {
        console.log("Error", error);
      }
    };
  }, []);

  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black z-[100]'>
      <span
        className='absolute top-6 left-6 cursor-pointer hover:opacity-60 z-[101]'
        onClick={onClose}
      >
        <ArrowLeft color='white' size={36} />
      </span>
      <ReactPlayer
        ref={videoRef}
        width={"100%"}
        height={"100%"}
        url={url}
        playing={true}
        onPlay={doFullscreen}
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        controls
        stopOnUnmount={false}
        pip
      />
    </div>
  );
}

export default VideoPlayer;
